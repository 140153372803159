import { ListItem, OrderedList, Stack, Table, TableContainer, Tbody, Td, Text, Thead, Tr } from '@chakra-ui/react';
import React from 'react';
import Footer from './Footer';
import Header from './Header';

function ServiceAndSupport(props) {
    return (
        <Stack spacing={'0'} w='100%'>
            <Header />
            <Stack py='100px' px='30px' w='100%'>
                <Text fontSize={'16px'} fontWeight={700}>Service and Support Description </Text>
                <Text fontSize={'14px'} fontWeight={700}>Part 1 Maintenance and Support </Text>
                <Text fontSize={'14px'} fontWeight={700}>1. Maintenance Events </Text>
                <OrderedList fontSize={'14px'} pl='20px' spacing={'20px'}>
                    <ListItem>
                        Routine planned maintenance of the hosting equipment, facility, Software or other aspects of the Hosting Services that may require interruption of the Hosting Services (“ <Text as={'span'} fontWeight={700}>Maintenance Events</Text>”) shall, except for any non-routine essential maintenance (“ <Text as={'span'} fontWeight={700}>Emergency Maintenance</Text>”), be performed during times that are intended to cause minimal disruption.<br />
                    </ListItem>
                    <ListItem>
                        Onvi may interrupt the Services to perform Emergency Maintenance and shall notify the Merchant of any Emergency Maintenance, where possible. In addition, Onvi may interrupt the Hosting Services outside Normal Business Hours for unscheduled maintenance, provided that it has given the Merchant at least three days’ advance notice. Any Maintenance Events which occur during Normal Business Hours, and which were not requested or caused by you, shall be considered downtime for the purpose of service availability measurement. Onvi shall at all times endeavour to keep any service interruptions to a minimum.
                        <br /><br />
                        <Text as={'span'} fontWeight={700}> Normal Business Hours:</Text> 7:00 am to 11:00 pm local UK time, every day of the week including Saturday and Sunday other than Christmas Day where availability will be 9am to 5pm Local UK Time.

                    </ListItem>
                </OrderedList>
                <Text fontSize={'14px'} fontWeight={700}>2. Maintenance </Text>
                <OrderedList fontSize={'14px'} pl='20px' spacing={'20px'}>
                    <ListItem>
                        Maintenance includes all regularly scheduled error corrections, Software updates and those upgrades limited to improvements to features described in the Software Specification.
                    </ListItem>
                    <ListItem>
                        Onvi shall maintain and update the Software. Should the Merchant determine that the Software includes a defect, the Merchant may at any time file error reports. During maintenance periods, Onvi may, at its discretion, upgrade versions, install error corrections and apply patches to the hosted systems. Onvi shall use all reasonable endeavours to avoid unscheduled downtime for Software maintenance.
                    </ListItem>
                </OrderedList>
                <Text fontSize={'14px'} fontWeight={700}>3. Technical support services </Text>
                <OrderedList fontSize={'14px'} pl='20px' spacing={'20px'}>
                    <ListItem>
                        Onvi technical support shall accept contact via email, to an email address provided to the Merchant, subject to change from time to time for incident submittal, 24 hours a day, seven days a week or any other methods as notified to the Merchant by Onvi from time to time. Onvi shall use reasonable endeavours to process support requests, issue trouble ticket tracking numbers if necessary, determine the source of the problem and respond to you. If the contact is made on a Public Holiday, Onvi may not respond until the next day which is not a Public Holiday.
                    </ListItem>
                    <ListItem>
                        Onvi shall directly notify the Merchant of Maintenance Events that may affect the availability of the Hosting Services.
                    </ListItem>
                    <ListItem>
                        The Merchant shall also provide support for data integration tools and processes developed or maintained by the Merchant in order to connect the Software to the Merchant’s other software and databases.
                    </ListItem>
                    <ListItem>
                        Before the Merchant or Onvi make changes to integration interfaces between the Software and the Merchant’s internal data stores or systems, the Merchant or Onvi shall provide notice to the other in order to ensure the continued operation of any integration interfaces affected by such changes. The Merchant shall provide Onvi with at least 60 days’ advance notice of such changes. Such notice shall include at least the new interface specifications and a technical contact to answer questions on these changes.
                    </ListItem>

                </OrderedList>
                <Text fontSize={'14px'} fontWeight={700}>Part 2 SLA and Service Agreement </Text>
                <Text fontSize={'14px'} fontWeight={700}>Onvi Service Level Agreement  </Text>
                <Text fontSize={'14px'}>The below outlines the agreement between Onvi and the Merchant for technical support covering onboarding as well as issues raised regarding the Onvi Application.  </Text>
                <TableContainer>
                <Table variant={'simple'} size={'lg'}fontSize={'14px'}>
                    <Thead>
                        <Tr fontWeight={700}>
                            <Td border={'1px solid black'}>Service </Td>
                            <Td border={'1px solid black'}>Level  </Td>
                            <Td border={'1px solid black'}>Description </Td>
                            <Td border={'1px solid black'}>Time Agreement  </Td>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td border={'1px solid black'}>Ongoing support </Td>
                            <Td border={'1px solid black'}>1st Line </Td>
                            <Td border={'1px solid black'}>
                                To identify and troubleshoot basic issues, offering workarounds where applicable. <br />
                                <Text as={'span'} fontWeight={700}> Examples:</Text> Printer setup and troubleshooting.
                            </Td>
                            <Td border={'1px solid black'}fontWeight={700}>As soon as possible </Td>
                        </Tr>

                        <Tr>
                            <Td border={'1px solid black'}>Ongoing support </Td>
                            <Td border={'1px solid black'}>2nd Line </Td>
                            <Td border={'1px solid black'}>
                                To troubleshoot technical issues requiring additional investigation, offering workarounds where applicable.
                                <Text as={'span'} fontWeight={700}> Example:</Text> Application functionality issues.

                            </Td>
                            <Td border={'1px solid black'}fontWeight={700}>As soon as possible </Td>
                        </Tr>
                    </Tbody>
                </Table>

                </TableContainer>
                <Text fontSize={'14px'} fontWeight={700}>1. Service availability </Text>
                <OrderedList fontSize={'14px'} pl='20px' spacing={'20px'}>
                    <ListItem>
                        Onvi shall use reasonable endeavours to ensure that the Software shall be available to the Merchant and its Customers no less than 98% of the time ("Uptime Service Level"). This availability refers to the Onvi Application on Onvi’s hosting network only and shall exclude issues caused by any other service provided by a third party.
                    </ListItem>
                    <ListItem>
                        Availability does not include Maintenance Events, Merchant-caused or third party-caused outages or disruptions, or outages or disruptions attributable in whole or in part to force majeure events within the meaning of clause 19.
                    </ListItem>
                    <ListItem>
                        If Onvi fails to meet the Uptime Service Level of 75% over any consecutive 4-month period during the Subscription Term, the Merchant may terminate the agreement it has with Onvi with immediate effect by giving notice in writing. The Uptime Service Level only refers to availability of the Onvi Application on Onvi’s hosting network and shall exclude issues caused by any other service provided by a third party.
                    </ListItem>
                </OrderedList>
                <Text fontSize={'14px'} fontWeight={700}>2. Availability measurement </Text>
                <OrderedList fontSize={'14px'} pl='20px' spacing={'20px'}>
                    <ListItem>
                        All measurements are performed at five-minute intervals and measure the availability of an availability test page within the Software within 30 seconds.
                    </ListItem>
                    <ListItem>
                        2Availability measurement shall be carried out by Onvi and is based on the monthly average percentage availability, calculated at the end of each calendar month as the total actual uptime minutes divided by total possible uptime minutes in the month. Onvi shall keep full records of its availability measurement activities under the Agreement.
                    </ListItem>
                </OrderedList>
            </Stack>
            <Footer />

        </Stack>
    );
}

export default ServiceAndSupport;