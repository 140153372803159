import { Box, Button, Flex, HStack, Image, Link, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { HiOutlineMail } from 'react-icons/hi'
function Header(props) {
    return (
        <HStack
            as="nav"
            align="center"
            justify="space-between"
            padding={4}
            bg="white"
            zIndex={'100'}
            w='100%'
            // mx={'auto'}
            pos={'fixed'}
            px={['20px', '40px']}
            fontSize={'14px'}
            spacing={'30px'}

        >
            {/* Left side */}
            <Stack align={'center'} mr={4}>
                <Image src={'https://onvi.com/images/logos/onvi-logo.svg'} alt="Logo" width="100%" height="100%" />
            </Stack>

            {/* Right side */}
            <Button colorScheme="blackAlpha" variant="solid" bg='black'
                fontSize={'14px'}
                leftIcon={<HiOutlineMail />}
                as={Link}
                href='https://onvi.com/contact-us/'
                target='_blank'
            >
                Contact Us
            </Button>
        </HStack>

    );
}

export default Header;