import { HStack, Image, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { BsInstagram, BsLinkedin } from 'react-icons/bs'
import { FaFacebook } from 'react-icons/fa'
import { AiOutlineTwitter } from 'react-icons/ai'


function Footer(props) {
    return (
        <Stack bg='#2a0064' px={['30px', '30px', '130px']} py='100px'w='100%'>
            <Stack direction={['column', 'column', 'row', 'row']}  spacing={'150px'}>
                <Stack spacing={'50px'}>
                    <Image SRC='https://onvi.com/images/logos/onvi-footer.svg'></Image>
                    <HStack spacing={'30px'}>
                        <a target='_blank' rel="noreferrer" href='https://uk.linkedin.com/company/onvihq' ><BsLinkedin color={'white'} size='32px' /> </a>
                        <a target='_blank' rel="noreferrer" href='https://web.facebook.com/OnviHQ?_rdc=1&_rdr'><FaFacebook color={'white'} size='32px' /> </a>
                        <a target='_blank' rel="noreferrer" href='https://twitter.com/onvihq' >  <AiOutlineTwitter color={'white'} size='32px' /> </a>
                        <a target='_blank' rel="noreferrer" href='https://www.instagram.com/onvihq/'>  <BsInstagram color={'white'} size='32px' /></a>

                    </HStack>

                </Stack>
                <Stack>
                    <Text fontSize={'16px'} fontWeight={'700'} color={'white'}>
                        All rights reserved. Onvi is a trading name of Onvi Tech Ltd, a company registered in England and Wales with company no. 10532973 and VAT registration no. GB246393488. The company is registered at Prince Albert House, 20 King Street, Maidenhead, Berkshire SL6 1DT, UK.
                    </Text>
                </Stack>
            </Stack>
            <Text pt='40px' fontSize={'16px'} fontWeight={'700'} color={'white'}>© 2023 Onvi Tech Ltd</Text>

        </Stack>
    );
}

export default Footer;