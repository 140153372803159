import { Link, List, ListItem, OrderedList, Stack, Text, UnorderedList } from '@chakra-ui/react';
import React from 'react';
import Header from './Header';
import Footer from './Footer';

function Home(props) {
    const listStyles = {
        listStyleType: "none",
        counterReset: "my-counter",
        "& li": {
            counterIncrement: "my-counter",
            paddingLeft: "20px",
            position: "relative",
            "&::before": {
                content: "'(' counter(my-counter, lower-alpha) ')'",
                position: "absolute",
                left: "0",
                fontWeight: "bold",
            },
        },
    };
    return (
        <Stack spacing={'0'} w='100%'>
            <Header />
            <Stack pt='150px' px='30px' w='100%'>
                <Text fontSize={'16px'} fontWeight={'700'}>PRIVACY POLICY</Text>
                <Text fontSize={'14px'} fontWeight={'700'}>1. Who we are and contact details</Text>
                <Text fontSize={'14px'}>
                    The controller is Onvi Tech Ltd and we are committed to complying with the UK’s data protection law and the EU General Data Protection Regulation (GDPR) for the protection of personal data, as well as the principles of data security in the configuration of our services. We can be contacted at our registered office address: <br /> <br />

                    Prince Albert House <br />
                    20 King Street, <br />
                    Maidenhead, <br />
                    Berkshire, <br />
                    SL6 1DT, <br />
                    United Kingdom <br /> <br />

                    If you have any questions about this privacy notice or how we use your personal data, please contact info@onvi.com <br /> <br />

                    Where you pay through the Onvi Application, we will also transfer your personal data to the Merchant you have paid. The Merchant is also a data controller in respect of your personal data and is required to use it in accordance with UK data protection law and GDPR. We have set out in this privacy notice information about how Merchants typically use your personal data. <br />


                </Text>
                <br />
                <Text fontSize={'14px'} fontWeight={'700'}>2. Who do we collect information about?<br /></Text>
                <Text fontSize={'14px'}> We collect information in two situations:<br /></Text>
                <UnorderedList fontSize={'14px'}>
                    <ListItem>For information about how we use and share information collected through use of the Onvi Application, please see sections 3 and 4. </ListItem>
                    <ListItem>For information about how we use information about Merchants, potential Merchants and other people who contact us through our website, please see section 5. </ListItem>

                </UnorderedList>
                <br />
                <Text fontSize={'14px'} fontWeight={'700'}>3. How do we use information in the Onvi Application?</Text>
                <Text fontSize={'14px'}>We use your personal data for the following purposes:</Text>
                <UnorderedList fontSize={'14px'}>
                    <ListItem>To allow you to pay the Merchant. This involves using a third party payment processor and we do not have access to your financial information as part of this process.   </ListItem>
                    <ListItem>Providing, optimising and maintaining the Onvi Application and ensuring that it remains secure.  This may involve monitoring traffic which may include your personal data. </ListItem>
                    <ListItem>Performing data analytics activity on the personal data in order to personalise the Onvi Application and any adverts contained within it for you based on your profile and preferences. </ListItem>
                    <ListItem>Creating aggregated, anonymised data sets so that we can understand market insights and trends.  We will share these trends with Merchants and other third parties but will not provide them with the underlying personal data. </ListItem>
                    <ListItem>To investigate fraud, spam, wrongful or unlawful use of our services. </ListItem>

                </UnorderedList>
                <Text fontSize={'14px'}>
                    In order to do this we will use the personal data which you provide directly when using the Onvi Application and information which is inferred from that information.  This may include the following categories of information:<br />
                </Text>
                <UnorderedList fontSize={'14px'}>
                    <ListItem>communication data (e.g. email); </ListItem>
                    <ListItem>age; </ListItem>
                    <ListItem>location data; </ListItem>
                    <ListItem>financial information excluding card information which is processed by a third party payment processor; </ListItem>
                    <ListItem>business and personal contact details; and </ListItem>
                    <ListItem>other personal data submitted to us. </ListItem>

                </UnorderedList>
                <Text fontSize={'14px'}>The lawful basis for using your personal data in this way is:</Text>
                <UnorderedList fontSize={'14px'}>
                    <ListItem>we act as the Merchant’s agent for concluding contracts with you, and use personal data in accordance with that contract; </ListItem>
                    <ListItem>we have a legitimate interest in using personal data in order to improve our services, provide insight to Merchants and maintain security.  We take precautions such as anonymisation in order to minimise any risk to you when doing this; </ListItem>
                    <ListItem>in some cases we may ask for your consent and will use your personal data in accordance with your preferences. </ListItem>

                </UnorderedList>
                <Text fontSize={'14px'}>We may also collect personal data using cookies — for more information see our <a href='https://onvi.com/cookie-policy'>Cookie Policy.</a></Text>
                <br />
                <Text fontSize={'14px'} fontWeight={'700'}>4. How do we share information with Merchants?</Text>
                <Text fontSize={'14px'}>
                    We share information with Merchants to enable them to process your payment. This includes processing refunds.  We will provide them with your name and your contact details in order to do this. This personal data is transferred and used by merchants in order to fulfil the contract you have entered into with them.
                    <br /> <br />
                    You may be asked whether you consent to the Merchant using your personal data to send marketing to you. It is your choice whether to give this consent and we require Merchants to use your personal data in accordance with any consent received.<br /> <br />

                </Text>
                <Text fontSize={'14px'} fontWeight={'700'}>5. How do we use information where you are interested in becoming a Merchant or contact us via our website?</Text>
                <Text fontSize={'14px'}>
                    We may collect personal data if you contact us for more information, including where you are interested in becoming a Merchant. This includes contact details for individuals working within a business. We will use your contact details in order to deal with your enquiry.  <br /> <br />

                    If you or that business subsequently enter into an agreement with us we will also collect data for the purposes of administering the account and to provide the products and services which have been requested from us. In order to enter into an agreement with Onvi Tech Ltd, we will collect, store and use elements of the personal data of contact individuals and those who will use the products and services we provide.    <br /> <br />

                    The lawful basis for processing of this personal data is our legitimate interests in providing a service to the party which has entered into a contract with us. The data we use about individuals is limited to business contact information and we take steps to limit the impact on the privacy of data subjects. We also monitor usage of the services (which may include personal data) in order to develop and improve our products, to improve security and to investigate fraud, spam and wrongful or unlawful use of the services.<br /> <br />

                    When engaging Onvi Tech Ltd you will usually need to disclose the following:<br /> <br />

                </Text>
                <UnorderedList fontSize={'14px'}>
                    <ListItem>title </ListItem>
                    <ListItem>first name(s), surname or company name (including representation as the case may be) </ListItem>
                    <ListItem>address (street, street number, postal code, city and country/region) </ListItem>
                    <ListItem>email address </ListItem>
                    <ListItem>telephone and mobile number </ListItem>
                    <ListItem>bank details (account number, account holder and card number) </ListItem>
                    <ListItem>VAT ID number (as the case may be) </ListItem>
                </UnorderedList>
                <Text fontSize={'14px'}>
                    We will also monitor, record, store and use any telephone call, email or other electronic communications with you for training purposes so that we can check any instructions given to us and to improve the quality of our customer service.<br /> <br />

                    We will also process your details through our internal systems to enable us to send you relevant communications. These include product and company updates, industry research, offers and promotions.  They also include service updates and notifications for Merchants. The lawful basis for processing this information internally will be Legitimate Interests. You have a right to opt-out of our direct marketing communications at any time. You can opt-out by following the unsubscribe instructions at the bottom of the communication or by emailing us.<br /> <br />

                </Text>
                <Text fontSize={'14px'} fontWeight={'700'}>6. Who do we disclose your personal data to?</Text>
                <Text fontSize={'14px'}>
                    Where you use the Onvi Application to place orders we will disclose your personal data to the Merchant.  Please see section 4.<br /> <br />

                    We use third party payment processors to process payments.  These include Checkout.com <Link href='https://www.checkout.com/'>(https://www.checkout.com/)</Link>, Stripe <Link href='https://stripe.com/en-gb'>(https://stripe.com/en-gb)</Link> and Square <Link href='https://squareup.com/gb/en'>(https://squareup.com/gb/en)</Link>.<br /> <br />

                    We may disclose your personal data to: <br /> <br />

                </Text>
                <UnorderedList fontSize={'14px'}>
                    <ListItem>Service providers acting as processors who provide IT and system administration services and who provide software services such as the provision of CRM systems to us. </ListItem>
                    <ListItem>Professional advisers acting as processors or joint controllers including lawyers, bankers, auditors and insurers based in the United Kingdom and the United States of America who provide consultancy, banking, legal, insurance and accounting services. </ListItem>
                    <ListItem>Third parties who audit our systems in relation to our ISO27001 compliance and compliance with other standards. </ListItem>
                    <ListItem>HM Revenue and Customs, regulators and other authorities acting as processors or joint controllers based in the UK who require reporting of processing activities in certain circumstances. </ListItem>
                </UnorderedList>
                <Text fontSize={'14px'}>We may disclose your personal data to third parties to whom we may choose to sell, transfer or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy notice.<br /> <br /> </Text>
                <Text fontSize={'14px'} fontWeight={'700'}>7. International Transfers</Text>
                <Text fontSize={'14px'}>We use service providers based outside the EEA as follows:</Text>
                <UnorderedList fontSize={'14px'}>
                    <ListItem>Google.  Google has also gained confirmation of compliance from European Data Protection Authorities for their model contract clauses, affirming that their current contractual commitments for G Suite and Google Cloud Platform fully meet the requirements under the data protection legislation to legally frame transfers of personal data from the EU to the rest of the world. These can be reviewed at the Google website; </ListItem>
                    <ListItem>Twillio. We use Twillio for sending SMS notifications and personal data may be transferred to the United States where its primary processing facilities are located.  Twillio employs appropriate safeguards for cross-border transfers of personal data including Binding Corporate Rules and Standard Contractual Clauses. </ListItem>
                </UnorderedList>
                <Text fontSize={'14px'} fontWeight={'700'}>8. Security</Text>
                <Text fontSize={'14px'}>
                    All information you provide to us is stored on our secure servers.<br /> <br />

                    Any payment transactions carried out by our chosen third-party provider of payment processing services will be encrypted using Secured Socket Layer (SSL) technology (with SHA-256 with RSA encryption).<br /> <br />

                    Where we have given you (or where you have chosen) a password that enables you to access certain parts of our services, you are responsible for keeping this password confidential. We ask you not to share a password with anyone.<br /> <br />

                    Once we have received your information, we will use strict procedures and security features to try to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way. Encryption-at-rest is used for your data when in storage, and encryption-in-transit is used during data communication. Backups are taken daily of your data, and stored securely for 7 days. Only those Onvi staff that require access to data for their job function are permitted to read data. In this case, Multi-Factor Authentication is required in the tooling necessary to access this data. All Onvi devices have disk encryption enabled. Databases are only accessible from the internal Onvi private network, and are not publicly exposed.<br /> <br />

                    We will collect and store personal data on your device using browser cookies and web storage.  Please see our <a href='https://onvi.com/cookie-policy' target='blank'>Cookie Policy</a> for more information.<br /> <br />

                    We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator when we are legally required to do so.<br /> <br />

                </Text>
                <Text fontSize={'14px'} fontWeight={'700'}>9. Data Retention</Text>
                <Text fontSize={'14px'}>Where possible, Onvi Tech Ltd will take steps to erase any personal data that is no longer necessary for the purposes for which it is collected or otherwise processed, or if you have withdrawn consent for its processing and retention where we rely on consent as the lawful basis for processing. As a general rule, if you currently have a contract or intend to enter into a contract with Onvi Tech Ltd, we will store the data for a period of six years after the contract ends, for compliance with our general legal obligations and for the exercise or defence of any legal claims.<br /> <br /></Text>
                <Text fontSize={'14px'} fontWeight={'700'}>10. Your rights</Text>
                <Text fontSize={'14px'}>You have the right to:</Text>
                <UnorderedList fontSize={'14px'}>
                    <ListItem>Request access to your personal data (commonly known as a "data subject access request"). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it. </ListItem>
                    <ListItem>Request correction of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us. </ListItem>
                    <ListItem>Request erasure of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.
                    </ListItem>
                    <ListItem>Object to processing of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms. </ListItem>
                    <ListItem>Request restriction of processing of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios:
                    </ListItem>

                    <OrderedList pl='20px' listStyleType={'lower-latin'} >
                        <ListItem> if you want us to establish the data's accuracy;</ListItem>
                        <ListItem> 	where our use of the data is unlawful but you do not want us to erase it; </ListItem>
                        <ListItem>where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or </ListItem>
                        <ListItem>you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it. </ListItem>

                    </OrderedList>
                    <ListItem>Request the transfer of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you. </ListItem>
                    <ListItem>Withdraw consent at any time where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent. </ListItem>
                </UnorderedList>
                <Text fontSize={'14px'} fontWeight={'700'}>11. Changes to our Privacy Notice</Text>
                <Text fontSize={'14px'}>
                    We keep our privacy notice under regular review.<br /> <br />

                    This version was last updated on 01 June 2023. It may change and if it does, these changes will be posted on this page and, where appropriate, notified to you when you next use our website or services. The new policy may be displayed on-screen and you may be required to read and accept the changes to continue your use of the Onvi Application or the services.<br /> <br />

                    It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during our relationship with you.<br /> <br />

                </Text>
                <Text fontSize={'14px'} fontWeight={'700'}>12. Complaints</Text>
                <Text fontSize={'14px'}>
                    If you have a complaint about any aspect of data protection or if you feel your privacy has been breached by us, we would like to hear from you. To help us investigate and resolve your concerns as quickly as possible, please contact info@onvi.com.<br /> <br />

                    If you are unhappy with the final response you have received from Onvi Tech Ltd, you have the right to complain to the supervisory authority, the Information Commissioner’s Office (ICO). You can call the ICO on 0303 123 1113 or by visiting their website: https://ico.org.uk/.<br /> <br />

                </Text>
                {/* <Text fontSize={'14px'} fontWeight={'700'}></Text>
            <Text fontSize={'14px'}></Text>
            <Text fontSize={'14px'} fontWeight={'700'}></Text>
            <Text fontSize={'14px'}></Text>
            <UnorderedList fontSize={'14px'}>
                <ListItem> </ListItem>
                <ListItem> </ListItem>
                <ListItem> </ListItem>
                <ListItem> </ListItem>
            </UnorderedList> */}

            </Stack>
            <Footer/>
        </Stack>
    );
}

export default Home;