import { Stack } from '@chakra-ui/react';
import React from 'react';
// import './index.css';
import Home from './Components/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ServiceAndSupport from './Components/ServiceAndSupport';

function App(props) {
  return (
  <BrowserRouter>
  <Routes>
    <Route path='/' element={<Home/>} />
    <Route path='/support' element={<ServiceAndSupport/>} />
  </Routes>
  </BrowserRouter>
  );
}

export default App;